<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <Spinner :spin="isProcessing" />
    <!-- Header -->
    <section class="header serial">
      <div class="flexH width">
        <a
          class="icon iconButton third"
          @click.prevent="$router.push('/profile')"
        >
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap">序號兌換</span>
      <div class="flexH width right">
        
      </div>
    </section>
    <!-- Main -->
    <section class="main serial">
      <div class="info flexV width padding center gapM">
        <ValidationObserver ref="observer" slim>
          <ValidationProvider slim rules="required" v-slot="{ errors }">
            <div class="input right rounded" :class="{error: errors.length}">
              <!-- class: error -->
              <div class="icon">
                <font-awesome-icon icon="fa-solid fa-ticket"></font-awesome-icon>
              </div>
              <span class="fM nowrap gray">優惠序號</span>
              <input
                ref="barcode"
                type="text"
                class="fM"
                placeholder="輸入序號"
                v-model="barcode"
              />
              <span v-if="errors.length" class="fS error message">必填</span> 
            </div>
          </ValidationProvider>
        </ValidationObserver>
        <ol class="fS gray">
          <li>輸入優惠序號即可獲得紅利桃子點數，成功兌換點數後，該序號就會失效，且無法更改、取消或回復。</li>
          <li>請於有效兌換期限前兌換完畢，若逾期則無法進行兌換。</li>
          <li>活動發放之優惠序號皆為不記名形式，獲得後請您妥善保管，若序號遺失、被他人盜用或逾期恕不補發。</li>
          <li>紅利桃子優惠序號獲得方式：參加並完成指定活動即可獲得，目前此序號於市面上暫無販售。</li>
          <li>本系統保留修改、變更規定及終止相關辦法之權利。</li>
        </ol>
        <a class="button rounded" @click.prevent="onConfirmClick()">
          <!-- <div class="icon">
            <font-awesome-icon
              icon="fa-solid fa-floppy-disk"
            ></font-awesome-icon>
          </div> -->
          <span class="fM">兌換</span>
          <!-- 提交申請 -->
        </a>
      </div>
    </section>
    <!-- Popup (Notice) -->
    <section id="popNotice" class="popup">
      <div class="popBody">
        <a class="close"><font-awesome-icon icon="fa-solid fa-times" /></a>
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain">
          <span class="fL">此條碼不存在或已被使用，請確認條碼是否輸入正確</span>
        </section>
        <section class="popFooter">
          <a class="button submit rounded"><span class="fM">確認</span></a>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner';
import { ValidationObserver, ValidationProvider} from 'vee-validate';


export default {
  name: 'CodeExchange',
  components: {
    Spinner,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      barcode: null,
      isProcessing: false,
      isFormValid: true
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        next('/error');
      }
    });
  },
  computed: {
    ...mapState(['user', 'userInfo']),
  },
  mounted() {
    this.$nextTick(function(){
      this.$refs.barcode.focus();
    });
  },
  methods: {
    popNotice() {
      var popNotice = document.getElementById('popNotice');
      var close = popNotice.getElementsByClassName('close')[0];
      var submit = popNotice.getElementsByClassName('submit')[0];
      close.onclick = function () {
        popNotice.style.display = 'none';
      };
      submit.onclick = function () {
        popNotice.style.display = 'none';
      };
      window.onclick = function (event) {
        if (event.target == popNotice) {
          popNotice.style.display = 'none';
        }
      };
      popNotice.style.display = 'flex';
    },
    onConfirmClick() {
      this.isProcessing = true;
      this.$refs.observer.validate()
      .then((valid) => {
        if (!valid) {
          this.isProcessing = false;
        } else {
          const entryCode = this.barcode.trim().toUpperCase();
          let data = JSON.stringify({
            entryCode: entryCode
          });
          var config = {
            method: 'post',
            url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/entries/check`,
            data : data
          };

          this.$http(config)
          .then(() => {
            this.$router.push({path: "/transfer", query: {transferCode: entryCode}});
          })
          .catch( e => {
            console.log(e);
            this.popNotice();
            this.isProcessing = false;
          })
        }
      }) 
    },
  },
};
</script>
